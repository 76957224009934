//----------------------------------------------------------------------------------------------------------------------
// # VARIABLES
//----------------------------------------------------------------------------------------------------------------------

/**
 * Variables should follow the `$component-state-property-size` formula for consistent naming.
 * Example: $nav-link-disabled-color and $modal-content-box-shadow-xs.
 *
 * Note: We can define vars before or after the _bootstrap-overrides.scss.
 *       Some variables are custom and some are standard Bootstrap 4 (they are here for convenience only).
 *
 * @see ./_bootstrap-overrides.scss To override Bootstrap 4 variables.
 */

//
// Fluid font sizes.
//

/**
 * Example: 768px / 16px <--- [16~18] ---> 1600px / 18px)
 */

$enable-fluid-font-sizes: false;
$fluid-font-sizes-min-width: 768px;
$fluid-font-sizes-max-width: 1600px;
$fluid-font-sizes-min-font: 16px;
$fluid-font-sizes-max-font: 18px;

//
// Fonts.
//

/**
 * [1] - Base: Google Open+Sans:400,400i,600,700.
 * [2] - Aux: Local 'Nexa Rust Sans Book': 400.
 */

$font-family-base: 'Montserrat', sans-serif !default; // [1]
$font-family-aux: 'Montserrat', sans-serif !default; // [2]
$font-family-aux-normal: 400;
//$font-family-aux-bold: 700;

//$font-weight-light: -;
//$font-weight-normal: -;
//$font-weight-medium: -;
$font-weight-semibold: 600;
//$font-weight-bold: -;

//
// Brand Colors.
//

/**
 * [1] - Accent is a hover & related with it color ($link-hover-color = $accent).
 *
 * @link https://graf1x.com/list-of-colors-with-color-names/ Help with color names.
 */

$primary: #0098da;
$primary-semi: #0570a0;

$secondary: #23a455;
$tertiary: #eefaff;


$primary-light: lighten($primary, 20%);
$primary-dark: darken($primary, 20%);
$secondary-lightest: lighten($secondary, 21%);
$secondary-dark: darken($secondary, 20%);;

$accent: #fc6600; // [1]
$gray: #adb5bd;

$light: #f8f9fa;
$dark: #343a40;

$header-light-color: #f8f9fa;
$header-light-bg: #f8f9fa;

$header-dark-color: #3c140a;
$header-dark-bg: #641e14;

$footer-color: #3c140a;
$footer-bg: #faf5f0;

$text-gray: #7e8b8f;

//
// Headings for big screens (md & xl).
//

/**
 * @link https://type-scale.com Help with font scale.
 */

$font-size-base: 1rem !default; // 16px
$font-size-base-mobile: 0.875rem !default; // 14/16

$h1-font-size: $font-size-base * 1.875 !default; // 30px
$h2-font-size: $font-size-base * 1.25 !default; // 20px
$h3-font-size: $font-size-base * 1.125 !default; // 18px
$h4-font-size: $font-size-base !default; // 16px
$h5-font-size: $font-size-base !default;
$h6-font-size: $font-size-base !default;

$h1-font-size-md: $font-size-base * 2.5; // 40px
$h2-font-size-md: $font-size-base * 1.875; // 30px
$h3-font-size-md: $font-size-base * 1.25; // 20px
$h4-font-size-md: $font-size-base * 1.125; // 18px
$h5-font-size-md: $font-size-base; // 16px
$h6-font-size-md: $font-size-base;

$h1-font-size-xl: $font-size-base * 3.75; // 60px
$h2-font-size-xl: $font-size-base * 2.5; // 40px
$h3-font-size-xl: $font-size-base * 1.875; // 30px
$h4-font-size-xl: $font-size-base * 1.25; // 20px
$h5-font-size-xl: $font-size-base * 1.125; // 18px
$h6-font-size-xl: $font-size-base; // 16px

//----------------------------------------------------------------------------------------------------------------------
@import 'bootstrap-overrides';
//----------------------------------------------------------------------------------------------------------------------

$grid-gutter-half: $grid-gutter-width / 2;

$component-focus-outline: $input-btn-focus-color solid $input-btn-focus-width;

//
// Font Awesome variables.
//

/**
 * @link https://fontawesome.com/icons?d=gallery Find an icon.
 */

$fa: 'Font Awesome 5 Free';
$fab: 'Font Awesome 5 Brands';
$fa-font-path: '../fonts';
$fa-font-display: swap;
