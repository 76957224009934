//----------------------------------------------------------------------------------------------------------------------
// # IMPORT - GOOGLE FONT
//

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;800&family=Mr+Dafoe&display=swap');


//----------------------------------------------------------------------------------------------------------------------
// # MIXINS - TYPOGRAPHY
//----------------------------------------------------------------------------------------------------------------------

/**
 * The different mixins for typography needs.
 *
 * ## Fluid font size.
 * ## Input placeholder.
 * ## Hidden text.
 * ## All headings at one.
 * ## Heading styles for H1-H6.
 * ## The default table mixin.
 * ## Truncating multiple line text.
 * ## List with a Fontawesome icon.
 * ## Fontawesome icon.
 */

//
// ## Fluid font size.
//

@mixin fluid-font-size($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;

      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }

      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

//
// ## Input placeholder.
//

@mixin placeholder() {
  &::-webkit-input-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

//
// ## Hidden text.
//

@mixin hide-text() {
  text-indent: -9999px;
  white-space: nowrap;
  overflow: hidden;
}

//
// ## All headings at one.
//

@mixin headings($with-classes: true) {
  @if ($with-classes) {
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 { /* stylelint-disable-line */
      @content;
    }
  } @else {
    h1, h2, h3, h4, h5, h6 { /* stylelint-disable-line */
      @content;
    }
  }
}

//
// ## Heading styles for H1-H6.
//

/**
 * Note: They affect to all website's headings.
 *
 * @see /node_modules/bootstrap/scss/_type.scss
 */

@mixin make-heading-h($number: null) {
  // H1-H6 (the default B4 properties).
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;

  // H1.
  @if ($number == 1) {
    font-family: $font-family-aux;
    font-size: $h1-font-size;
    font-weight: $font-family-aux-normal;

    @include media-breakpoint-up(md) {
      font-size: $h1-font-size-md;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h1-font-size-xl;
    }
  }

  // H2.
  @if ($number == 2) {
    font-family: $font-family-aux;
    font-size: $h2-font-size;
    font-weight: $font-family-aux-normal;

    @include media-breakpoint-up(md) {
      font-size: $h2-font-size-md;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h2-font-size-xl;
    }
  }

  // H3.
  @if ($number == 3) {
    font-family: $font-family-aux;
    font-size: $h3-font-size;
    font-weight: $font-family-aux-normal;

    @include media-breakpoint-up(md) {
      font-size: $h3-font-size-md;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h3-font-size-xl;
    }
  }

  // H4.
  @if ($number == 4) {
    font-family: $font-family-aux;
    font-size: $h4-font-size;
    font-weight: $font-family-aux-normal;

    @include media-breakpoint-up(md) {
      font-size: $h4-font-size-md;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h4-font-size-xl;
    }
  }

  // H5.
  @if ($number == 5) {
    font-family: $font-family-aux;
    font-size: $h5-font-size;
    font-weight: $font-family-aux-normal;

    @include media-breakpoint-up(md) {
      font-size: $h5-font-size-md;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h5-font-size-xl;
    }
  }


  // H6.
  @if ($number == 6) {
    font-family: $font-family-aux;
    font-size: $h6-font-size;
    font-weight: $font-family-aux-normal;

    @include media-breakpoint-up(md) {
      font-size: $h6-font-size-md;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h6-font-size-xl;
    }
  }
}

//
// ## The default table mixin.
//

/**
 * It is default Bootstrap4 .table {} base styles.
 *
 * Warning: Be careful with changes, this affects for ANY <table>!
 *
 * [1] - Reset background color for nesting within parents with `background-color`.
 */

@mixin table {
  width: 100%;
  margin-bottom: $spacer;
  color: $table-color;
  background-color: $table-bg; // [1]

  th,
  td {
    padding: $table-cell-padding;
    vertical-align: top;
    border-top: $table-border-width solid $table-border-color;
  }

  thead th {
    vertical-align: bottom;
    border-bottom: (2 * $table-border-width) solid $table-border-color;
  }

  tbody + tbody {
    border-top: (2 * $table-border-width) solid $table-border-color;
  }
}


//
// ## Truncating multiple line text.
//

/**
 * text-truncate() has auto height and works anywhere except IE (it will show all text).
 * text-truncate-height() works anywhere well, but IE will cut without '...'.
 *
 * [1] - Fallback for IE.
 * [2] - Set calculated block's height.
 *
 * @link https://css-tricks.com/line-clampin/
 */

@mixin text-truncate($lines-count: 1) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines-count;
  overflow: hidden;
}

@mixin text-truncate-height($lines-count: 1, $font-size: $font-size-base, $line-height: $line-height-base) {
  display: block; // [1]
  display: -webkit-box;
  height: strip-unit($lines-count) * $font-size * strip-unit($line-height); // [2]
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines-count;
  overflow: hidden;
}

//
// ## List with a Fontawesome icon (by default it's '>').
//

@mixin list-with-fontawesome-icon($icon: '\f105', $color: currentColor, $size: 1rem, $line-height: 1.5) {
  padding-left: 0;
  list-style: none;

  li {
    position: relative;
    padding-left: $size*1.5;
    line-height: $line-height * $size;

    &::before {
      content: $icon;
      display: inline-block;
      position: absolute;
      top: ($line-height * $size - $size) / 2;
      left: 0;
      width: $size;
      height: $size;
      font-family: $fa;
      font-size: $size;
      font-weight: 900;
      line-height: 1;
      vertical-align: middle;
      color: $color;
    }
  }
}

//
// ## Fontawesome icon.
//

@mixin make-fa-icon($code: '', $font: 'Font Awesome 5 Free', $font-weight: 900) {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-family: $font;
  font-weight: $font-weight;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  speak: none;

  &::before {
    content: $code;
  }
}

//
// ## ...
//
