//----------------------------------------------------------------------------------------------------------------------
// # MIXINS - LINKS // TODO-K Core: Think more, perhaps we can change these mixins.
//----------------------------------------------------------------------------------------------------------------------

/*
 * This links mixins are very sensitive and widely used, please, try don't change them.
 */

@mixin link($primary-color: #007bff, $accent-color: #fd7e14, $transition: all 0.2s ease-in-out) {
  display: inline;
  color: $primary-color;
  text-decoration: $link-decoration;
  transition: $transition;

  &:hover,
  &:focus,
  &:active {
    color: $accent-color;
    text-decoration: $link-hover-decoration;
  }
}

@mixin link-block {
  display: block;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

@mixin link-inline-block() {
  display: inline-block;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

@mixin link-unstyled() {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    line-height: inherit;
    text-decoration: inherit;
    color: inherit;
    background-color: initial;
    background-image: none;
    background-size: 0;
    cursor: pointer;
  }
}

@mixin link-inherited($accent-color: #fd7e14) {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    color: $accent-color;
  }
}


@mixin link-with-fontawesome-icon($color: #000, $hover-color: #000) {
  position: relative;
  display: inline-block;

  &[href^='tel:'],
  &[href^='mailto:'],
  &[href*='google.com/maps'],
  &[href*='goo.gl/maps'] {
    padding-left: 2em;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      font-family: $fa;
      font-size: 1em;
      font-weight: 700;
      color: $color;
      transform: translateY(-50%);
    }

    &:hover,
    &:focus,
    &:active {
      &::before {
        color: $hover-color;
      }
    }
  }

  &[href^='tel:']::before {
    content: '\f095';
    transform: translateY(-50%) rotate(90deg);
  }

  &[href^='mailto:']::before {
    content: '\f0e0';
  }

  &[href*='/google.com/maps']::before,
  &[href*='/goo.gl/maps']::before {
    content: '\f3c5';
  }
}

@mixin link-with-svg-icon($color: #000, $hover-color: #000) {
  position: relative;
  display: inline-block;

  &[href^='tel:'],
  &[href^='mailto:'],
  &[href*='google.com/maps'],
  &[href*='goo.gl/maps'] {
    padding-left: 2em;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      font-size: inherit;
      width: 1.5em;
      height: 1.5em;
      color: $color;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      transform: translateY(-50%);
    }
  }

  // Normal.

  &[href^='tel:']::before {
    background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" width="23.996" height="23.996" viewBox="0 0 23.996 23.996"><path d="M508.529,2173.007a12,12,0,1,0,12,12A12,12,0,0,0,508.529,2173.007Zm6.952,17.861-.872.872a2.624,2.624,0,0,1-3.3.331,35.174,35.174,0,0,1-9.847-9.847,2.631,2.631,0,0,1,.331-3.3l.872-.872a1.872,1.872,0,0,1,2.649,0l1.59,1.589a1.877,1.877,0,0,1,0,2.65l-.289.288a35.15,35.15,0,0,0,4.338,4.338l.289-.288a1.873,1.873,0,0,1,2.649,0l1.59,1.59A1.876,1.876,0,0,1,515.481,2190.868Z" transform="translate(-496.531 -2173.007)" fill="#{$color}"/></svg>');
  }

  &[href^='mailto:']::before {
    background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g transform="translate(-592.531 -2719.007)"><path d="M604.531,2719.007a12,12,0,1,0,12,12A12.013,12.013,0,0,0,604.531,2719.007Zm8,16a2,2,0,0,1-2,2h-12a2,2,0,0,1-2-2v-8a2,2,0,0,1,2-2h12a2,2,0,0,1,2,2Z" fill="#{$color}"/><path d="M610.418,2727.19a.5.5,0,0,0-.7-.07l-5.183,4.241-5.184-4.241a.5.5,0,1,0-.633.774l3.618,2.96-3.566,2.229a.5.5,0,0,0,.265.924.5.5,0,0,0,.265-.076l3.853-2.408,1.065.871a.5.5,0,0,0,.633,0l1.066-.871,3.853,2.408a.492.492,0,0,0,.264.076.5.5,0,0,0,.266-.924l-3.566-2.229,3.617-2.96A.5.5,0,0,0,610.418,2727.19Z" fill="#{$color}"/></g></svg>');
  }

  &[href*='google.com/maps']::before,
  &[href*='goo.gl/maps']::before {
    background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M1012.531,799.006a12,12,0,1,0,12,12A12.014,12.014,0,0,0,1012.531,799.006Zm5.444,7.231-6.5,12.5a.5.5,0,0,1-.444.269.489.489,0,0,1-.119-.014.5.5,0,0,1-.381-.486v-5.5h-5.5a.5.5,0,0,1-.231-.943l12.5-6.5a.5.5,0,0,1,.675.674Z" transform="translate(-1000.531 -799.006)" fill="#{$color}"/></svg>');
  }

  // Hovered.

  &[href^='tel:']:hover::before {
    background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" width="23.996" height="23.996" viewBox="0 0 23.996 23.996"><path d="M508.529,2173.007a12,12,0,1,0,12,12A12,12,0,0,0,508.529,2173.007Zm6.952,17.861-.872.872a2.624,2.624,0,0,1-3.3.331,35.174,35.174,0,0,1-9.847-9.847,2.631,2.631,0,0,1,.331-3.3l.872-.872a1.872,1.872,0,0,1,2.649,0l1.59,1.589a1.877,1.877,0,0,1,0,2.65l-.289.288a35.15,35.15,0,0,0,4.338,4.338l.289-.288a1.873,1.873,0,0,1,2.649,0l1.59,1.59A1.876,1.876,0,0,1,515.481,2190.868Z" transform="translate(-496.531 -2173.007)" fill="#{$hover-color}"/></svg>');
  }

  &[href^='mailto:']:hover::before {
    background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g transform="translate(-592.531 -2719.007)"><path d="M604.531,2719.007a12,12,0,1,0,12,12A12.013,12.013,0,0,0,604.531,2719.007Zm8,16a2,2,0,0,1-2,2h-12a2,2,0,0,1-2-2v-8a2,2,0,0,1,2-2h12a2,2,0,0,1,2,2Z" fill="#{$hover-color}"/><path d="M610.418,2727.19a.5.5,0,0,0-.7-.07l-5.183,4.241-5.184-4.241a.5.5,0,1,0-.633.774l3.618,2.96-3.566,2.229a.5.5,0,0,0,.265.924.5.5,0,0,0,.265-.076l3.853-2.408,1.065.871a.5.5,0,0,0,.633,0l1.066-.871,3.853,2.408a.492.492,0,0,0,.264.076.5.5,0,0,0,.266-.924l-3.566-2.229,3.617-2.96A.5.5,0,0,0,610.418,2727.19Z" fill="#{$hover-color}"/></g></svg>');
  }

  &[href*='google.com/maps']:hover::before,
  &[href*='goo.gl/maps']:hover::before {
    background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M1012.531,799.006a12,12,0,1,0,12,12A12.014,12.014,0,0,0,1012.531,799.006Zm5.444,7.231-6.5,12.5a.5.5,0,0,1-.444.269.489.489,0,0,1-.119-.014.5.5,0,0,1-.381-.486v-5.5h-5.5a.5.5,0,0,1-.231-.943l12.5-6.5a.5.5,0,0,1,.675.674Z" transform="translate(-1000.531 -799.006)" fill="#{$hover-color}"/></svg>');
  }
}

@mixin thumbnail-link-hover-effect() {
  @include hover-focus-active() {
    opacity: 0.75;
  }
}
