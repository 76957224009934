//----------------------------------------------------------------------------------------------------------------------
// # MIXINS - GENERAL
//----------------------------------------------------------------------------------------------------------------------

/**
 * Here are different mixins with very different purposes.
 *
 * ## Debug grid.
 * ## Last row.
 * ## Animated loader.
 * ## Background in the center.
 * ## Responsive rectangle.
 * ## Dropdown arrow.
 * ## Section responsive vertical indent.
 */

//
// ## Debug grid.
//

@mixin debug-outline($color: #f00, $is-grid: true, $grid-size: 10px) {
  outline: 1px solid rgba($color, 0.8);
  outline-offset: -1px;

  // The grid with cells & lines (the last two).

  @if ($is-grid) {
    background-color: transparent;
    background-image: linear-gradient(45deg, rgba($color, 0.15) 25%, transparent 25%), linear-gradient(-45deg, rgba($color, 0.15) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgba($color, 0.15) 75%), linear-gradient(-45deg, transparent 75%, rgba($color, 0.15) 75%), linear-gradient(0deg, rgba($color, 0.3) 1px, transparent 1px), linear-gradient(90deg, rgba($color, 0.3) 1px, transparent 1px);
    background-size: $grid-size*2 $grid-size*2, $grid-size*2 $grid-size*2, $grid-size*2 $grid-size*2, $grid-size*2 $grid-size*2, $grid-size*10 $grid-size*10, $grid-size*10 $grid-size*10;
    background-position: 0 0, 0 $grid-size, $grid-size (-$grid-size), -$grid-size 0;
  }
}

//
// ## Last row.
//

/**
 * Apply styles to the last row of grid elements only. It depends on columns number.
 */

@mixin last-grid-row($columns) {
  &:nth-child(#{$columns}n+1):nth-last-child(-n+#{$columns}),
  &:nth-child(#{$columns}n+1):nth-last-child(-n+#{$columns}) ~ * {
    @content;
  }
}

//
// ## Animated loader.
//

@mixin loader() {
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -0.5rem;
    margin-top: -0.5rem;
    height: 1rem;
    width: 1rem;
    font-size: 2rem;
    line-height: 1;
    text-align: center;
    color: rgba(#000, 0.75);
    background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91.3 91.1"><circle cx="45.7" cy="45.7" r="45.7"/><circle fill="#fff" cx="45.7" cy="24.4" r="12.5"/></svg>');
    background-position: 50% 50%;
    background-size: cover;
    animation: spin 1s ease-in-out infinite;
  }
}

//
// ## Background in the center.
//

@mixin bg-in-center($bg-size: cover) {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: $bg-size;
}

//
// ## Responsive rectangle.
//

/**
 * Note: It depends from the parent's width.
 *
 * [1] - $width & $height is % only!
 * [2] - Really it's not height.
 * [3] - padding-bottom = real height in % from width.
 */

@mixin responsive-rectangle($width: 100%, $height: 66%) { // [1]
  display: block;
  width: $width;
  height: 0; // [2]
  padding: 0 0 ($width/100%)*($height/100%)*100%; // [3]
}

//
// ## Dropdown arrow.
//

/**
 * The dropdown arrow for a select element.
 *
 * [1] - Background-image sets a drop-down triangle svg.
 */

@mixin select-arrow($padding: 0.5rem, $svg: false, $color: #000) {
  @if $svg == false {
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#{$color}" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg>';
  }

  padding-right: $padding*3;
  -webkit-appearance: none;
  appearance: none;
  background-image: inline-svg($svg); // [1]
  background-repeat: no-repeat;
  background-origin: padding-box;
  background-position: right $padding center;
  background-size: $padding auto;
}

//
// ## Section responsive vertical indent.
//

@mixin section-vertical-indent($property: 'margin', $position: 'top', $action: 'add') {
  $xs: 0;
  $md: 0;
  $xxl: 0;

  @if $action == 'add' {
    $xs: 2.5rem;
    $md: 3.5rem;
    $xxl: 4.5rem;
  }

  @if ($position == 'top' or $position == 'both') {
    #{$property}-top: $xs;

    @include media-breakpoint-up(md) {
      #{$property}-top: $md;
    }

    @include media-breakpoint-up(xxl) {
      #{$property}-top: $xxl;
    }
  }

  @if ($position == 'bottom' or $position == 'both') {
    #{$property}-bottom: $xs;

    @include media-breakpoint-up(md) {
      #{$property}-bottom: $md;
    }

    @include media-breakpoint-up(xxl) {
      #{$property}-bottom: $xxl;
    }
  }
}

//
// ## Border -----------------------------------------------------------------------------------------------------------
//

//
// Main Website Border -------------------------------------------------------------------------------------------------
//

@mixin border($color: $border-color) {
  border: $border-width solid $color;
  border-radius: $border-radius;
}

//
// ## ------------------------------------------------------------------------------------------------------------------
//
