@charset "UTF-8";
//----------------------------------------------------------------------------------------------------------------------
// LOGIN STYLES
//----------------------------------------------------------------------------------------------------------------------

// Abstracts -> Settings.
@import 'abstracts/variables';

// Abstracts -> Tools.
@import 'abstracts/functions';
@import 'abstracts/_mixins';
@import 'abstracts/animations';


/**
 * Login Customization.
 *
 * Important: Don't change the selector structure! It is WP default selectors.
 */

// The page background.

/* stylelint-disable selector-max-specificity */

body.login {
  background-color: $white;
  background-image: linear-gradient(180deg, $white, lighten($secondary, 25%) 75%, $secondary);
  background-repeat: repeat;
  background-position: 0 0;
}

body.login div#login {

  // The WordPress logo.

  h1 a {
    // background-image: in PHP;
    background-size: contain;
    background-position: 50% 50%;
    width: auto;
    height: 7.5rem;
  }

  // The Lost Your Password link

  p#nav a {
    @include link($primary, $accent);
  }

  // The Back to link

  p#backtoblog a {
    @include link($primary, $accent);
  }
}

// The white box that contains the form.

body.login div#login form#loginform {

  // The username and password field labels.

  //form#loginform p label {}

  // Both input fields (username and password)

  //form#loginform input {}

  // Just the username input field

  //form#loginform input#user_login {}

  // Just the password input field

  //form#loginform input#user_pass {}

  // The Remember Me field

  //form#loginform p.forgetmenot {}

  // The Submit button

  //form#loginform p.submit input#wp-submit {}
}

/* stylelint-enable selector-max-specificity */
