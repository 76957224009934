//----------------------------------------------------------------------------------------------------------------------
// ANIMATIONS
//----------------------------------------------------------------------------------------------------------------------

/**
 * Important: No classes here, only @keyframes!
 */

//
// ## Button after element spin.
//

/**
 * The analog fa-spin animation, but it contains translateY(-50%) for button decor vertical alignment.
 */

@keyframes btn-after-element-spin {
  0% {
    transform: translateY(-50%) rotate(0deg);
  }

  100% {
    transform: translateY(-50%) rotate(360deg);
  }
}
