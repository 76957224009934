//----------------------------------------------------------------------------------------------------------------------
// # FUNCTIONS
//----------------------------------------------------------------------------------------------------------------------

/**
 * ## Remove units.
 * ## Replace string.
 * ## Encode symbols.
 * ## Inline SVG.
 * ## Tint and shade color.
 */

//
// ## Remove units.
//

/**
 * Remove units from the value. Example: 16px ---> 16.
 */

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

//
// ## Replace string.
//

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

//
// ## Encode symbols.
//

@function url-encode($string) {
  $map: (
    '%': '%25',
    '<': '%3C',
    '>': '%3E',
    ' ': '%20',
    '!': '%21',
    '*': '%2A',
    '\'': '%27',
    '"': '%22',
    '(': '%28',
    ')': '%29',
    ';': '%3B',
    ':': '%3A',
    '@': '%40',
    '&': '%26',
    '=': '%3D',
    '+': '%2B',
    '$': '%24',
    ',': '%2C',
    '/': '%2F',
    '?': '%3F',
    '#': '%23',
    '[': '%5B',
    ']': '%5D'
  );

  $new: $string;

  @each $search, $replace in $map {
    $new: str-replace($new, $search, $replace);
  }

  @return $new;
}


//
// ## Inline SVG.
//

@function inline-svg($string) {
  @return url('data:image/svg+xml,#{url-encode($string)}');
}

//
// ## Tint and shade color.
//

/**
 * The tint() and shade() color functions used to be available as built-in functions in older versions of Sass when it
 * was written in Ruby. But with the actual LibSass versions written in C++ we must implement them as Sass functions.
 *
 * tint() - Returns a lighter color. 0% is the same color, 100% is white.
 * shade() - Returns a darker color. 0% is the same color, 100% is black.
 */

@function tint($color, $percent: 0%, $alpha: 1) {
  @return rgba(mix(white, $color, $percent), $alpha);
}

@function shade($color, $percent: 0%, $alpha: 1) {
  @return rgba(mix(black, $color, $percent), $alpha);
}

//
// ## Rem Calc.
//
//@function projects-list($values) {
//  $rem-values: $values / 16px * 1rem;
//
//  @return $rem-values;
//}
@function rem-calc($values, $base: null) {
  $rem-values: ();
  $count: length($values);

  // If no base is defined, defer to the global font size
  @if $base == null {
    $base: $font-size-base;
  }

  // If the base font size is a %, then multiply it by 16px
  // This is because 100% font size = 16px in most all browsers
  @if unit($base) == '%' {
    $base: ($base / 100%) * 16px;
  }

  // Using rem as base allows correct scaling
  @if unit($base) == 'rem' {
    $base: strip-unit($base) * 16px;
  }

  @if $count == 1 {
    @return to-rem($values, $base);
  }

  @for $i from 1 through $count {
    $rem-values: append($rem-values, to-rem(nth($values, $i), $base));
  }

  @return $rem-values;
}

@function to-rem($value, $base: null) {
  // Check if the value is a number
  @if type-of($value) != 'number' {
    @warn inspect($value) + ' was passed to rem-calc(), which is not a number.';
    @return $value;
  }

  // Transform em into rem if someone hands over 'em's
  @if unit($value) == 'em' {
    $value: strip-unit($value) * 1rem;
  }

  // Calculate rem if units for $value is not rem or em
  @if unit($value) != 'rem' {
    $value: strip-unit($value) / strip-unit($base) * 1rem;
  }

  // Turn 0rem into 0
  @if $value == 0rem {
    $value: 0;
  }

  @return $value;
}


//
// ## ...
//
