//----------------------------------------------------------------------------------------------------------------------
// # MIXINS - BUTTONS
//----------------------------------------------------------------------------------------------------------------------

/**
 * All our btn-{name} mixins are wrappers and close to copy of Bootstrap code with some customization.
 * The Bootstrap doesn't provide the similar mixins, for example .btn().
 * We need these mixins for simple total customization of ANY website button.
 *
 * More info here:
 * ~bootstrap/scss/mixins/_buttons.scss
 * ~bootstrap/scss/_buttons.scss
 *
 * Warning: Never add explicit styles (btn--primary, etc) for default button tags like <button>, <input type='button'>,
 * <input type='submit'>, etc. For next components styling it is much better if they are clean!
 */

/**
 * ## Base button.
 * ## Alternate buttons.
 * ## Link buttons.
 * ## Button sizes.
 * ## Block button.
 */

//
// ## Base button ------------------------------------------------------------------------------------------------------
//

/**
 * The base styles button mixin. It is very close to default bootstrap's .btn {} code with some customization.
 *
 * Note: We use BEM version of buttons.
 */

@mixin btn() {
  display: inline-block;
  position: relative; // custom
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  color: $body-color;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  white-space: $btn-white-space;
  vertical-align: middle;
  cursor: if($enable-pointer-cursor-for-buttons, pointer, null);
  user-select: none;
  background-color: transparent;
  border: $btn-border-width solid transparent;

  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);

  // The Customization after Bootstrap's code.
  // ... {your code}

  @include media-breakpoint-down(sm) {
    max-width: 18.75rem; // custom
    font-size: $font-size-sm;
  }

  @include not-disabled() {
    @include hover-focus() {
      // ... {your code}
    }
  }

  @include hover() {
    color: $body-color;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    text-decoration: none;
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle.

  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    cursor: default; // custom

    @include box-shadow(none);
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }
}

//
// ## Alternate buttons ------------------------------------------------------------------------------------------------
//

/**
 * The alternate buttons mixins are wrappers for standard Bootstrap's mixins, but with some additional customization.
 *
 * Note: Here is important the $yiq-contrasted-threshold variable's value for a label color.
 */

@mixin btn-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%)) {
  @include button-variant($background, $border, $hover-background, $hover-border, $active-background, $active-border);

  // Additional customization below.
  // ... {your code}
}

@mixin btn-outline-variant($color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color) {
  @include button-outline-variant($color, $color-hover, $active-background, $active-border);

  // Additional customization below.
  background-color: transparent;
  border-color: $border-color;
}

//
// ## Link buttons -----------------------------------------------------------------------------------------------------
//

/**
 * Make a button look and behave like a link.
 */

@mixin btn-link() {
  font-weight: $font-weight-normal;
  color: $link-color;
  text-decoration: $link-decoration;

  @include hover() {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  // No need for an active state here.
}

//
// ## Button sizes -----------------------------------------------------------------------------------------------------
//

@mixin btn-lg() {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);

  // Additional customization below.
  // ... {your code}
}

@mixin btn-sm() {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);

  // Additional customization below
  // ... {your code}
}

//
// ## Block button -----------------------------------------------------------------------------------------------------
//

@mixin btn-block() {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons

  & + .btn--block {
    margin-top: $btn-block-spacing-y;
  }
}

//
// ## ------------------------------------------------------------------------------------------------------------------
//
