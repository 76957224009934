//----------------------------------------------------------------------------------------------------------------------
// # MIXINS - SVG
//----------------------------------------------------------------------------------------------------------------------

/**
 * ## SVG fill & strock.
 */

//
// ## SVG fill & strock.
//

/**
 * Important: These SVG-mixins are very sensitive. So, don't change them at all!
 */

@mixin svg-fill($color: currentColor) {
  &,
  * {
    &[fill] {
      fill: $color;
    }

    &[fill='none'] {
      fill: none;
    }
  }
}

@mixin svg-stroke($color: currentColor) {
  &,
  * {
    &[stroke] {
      stroke: $color;
    }

    &[stroke='none'] {
      stroke: none;
    }
  }
}

@mixin svg-fill-and-stroke($color: currentColor) {
  @include svg-fill($color);
  @include svg-stroke($color);
}
